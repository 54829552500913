import React from "react";
import styles from "./styles.module.scss";
import Skeleton from "../../Skeleton";

const FallCardSkeleton = ({ room }) => {
  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <h3>{room}</h3>
      </div>
      <div className={styles.row}>
        <Skeleton width="47%" height="80px" />
        <Skeleton width="47%" height="80px" />
      </div>

      <div className={styles.row}>
        <Skeleton width="47%" height="40px" />
      </div>
    </div>
  );
};

export default FallCardSkeleton;
