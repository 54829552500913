import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import ButtonSpinner from "../../Spinners/ButtonSpinner";

const LilliButton = ({
  variant = "primary",
  children,
  onClick,
  disabled,
  leftIcon,
  rightIcon,
  type,
  loading,
}) => {
  return (
    <button
      type={type || "button"}
      onClick={onClick}
      disabled={disabled}
      className={classNames(styles.button, {
        [styles.primary]: variant === "primary",
        [styles.secondary]: variant === "secondary",
        [styles.danger]: variant === "danger",
        [styles.danger_secondary]: variant === "dangerSecondary",
      })}
    >
      {loading ? (
        <div className={styles.left_icon}>
          <ButtonSpinner />
        </div>
      ) : null}
      {leftIcon && !loading ? (
        <div className={styles.left_icon}>{leftIcon}</div>
      ) : null}
      <div>{children}</div>
      {rightIcon ? <div className={styles.right_icon}>{rightIcon}</div> : null}
    </button>
  );
};

export default LilliButton;
