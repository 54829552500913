import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const serviceUsersDataSlice = createSlice({
  name: "serviceUsersData",
  initialState,
  reducers: {
    setServiceUsersData(_, action) {
      return action.payload;
    },
    updateServiceUserData(state, action) {
      state[action.payload.hubId] = {
        ...state[action.payload.hubId],
        ...action.payload.update,
      };
    },
    updateServiceUserFalls(state, action) {
      const { hubId, fallId, status, event } = action.payload;

      // Find the correct fall object based on fallId
      const fall = state[hubId]?.falls.find((fall) => fall.id === fallId);

      if (fall) {
        // Push the new event into the existing events array
        fall.currentStatus = status;
        fall.events.push(event);
      } else {
        console.error(`Fall with id ${fallId} not found in hub ${hubId}`);
      }
    },
    updateServiceUserEvents(state, action) {
      const { hubId, key, events } = action.payload;
      state[hubId] = {
        ...state[hubId],
        events: {
          ...(state[hubId]?.events || {}),
          [key]: events,
        },
      };
    },
  },
});

export const {
  setServiceUsersData,
  updateServiceUserData,
  updateServiceUserEvents,
  updateServiceUserFalls,
} = serviceUsersDataSlice.actions;
export default serviceUsersDataSlice.reducer;
