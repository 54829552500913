import { differenceInHours, differenceInDays } from "date-fns";

const isOlderThan72Hours = (timestamp) => {
  const now = new Date();
  const fallTime = new Date(timestamp);
  const diffInHours = differenceInHours(now, fallTime);
  return diffInHours > 72;
};

export const isLessThanEqualTo7DaysOld = (timestamp) => {
  const now = new Date();
  const fallTime = new Date(timestamp);
  const diffInDays = differenceInDays(now, fallTime);
  return diffInDays <= 7;
};

export const groupFallsData = (serviceUsersData, serviceUsers) => {
  const liveFalls = [];
  const recentFalls = [];
  const otherUsers = [];

  if (!serviceUsersData || typeof serviceUsersData !== "object") {
    return { liveFalls, recentFalls, otherUsers };
  }

  serviceUsers.forEach((serviceUser) => {
    // Get any falls data we have for the service user
    const sUFallsData = serviceUsersData[serviceUser.id];
    // If they haven't had any falls, add them to the otherUsers array
    if (!sUFallsData || !sUFallsData.falls || sUFallsData.falls.length === 0) {
      otherUsers.push({
        room: serviceUser.userstring,
        currentStatus: "never-fallen",
        serviceUserId: serviceUser.id,
      });
      return;
    }
    // Get the last fall the SU had
    const lastFallWithoutFalseAlarm = sUFallsData.falls.findLast(
      (fall) =>
        fall.events[fall.events.length - 1].metadata?.falseAlarm !== true
    );

    // If they don't have any falls that weren't false alarms, add them to the otherUsers array
    if (!lastFallWithoutFalseAlarm) {
      otherUsers.push({
        room: serviceUser.userstring,
        currentStatus: "never-fallen",
      });
      return;
    }

    if (
      lastFallWithoutFalseAlarm.currentStatus === "detected" ||
      lastFallWithoutFalseAlarm.currentStatus === "acknowledged"
    ) {
      liveFalls.push({
        ...lastFallWithoutFalseAlarm,
        room: serviceUser.userstring,
        serviceUserId: serviceUser.id,
      });
    } else if (
      lastFallWithoutFalseAlarm.timestamp &&
      !isOlderThan72Hours(lastFallWithoutFalseAlarm.timestamp)
    ) {
      recentFalls.push({
        ...lastFallWithoutFalseAlarm,
        room: serviceUser.userstring,
        serviceUserId: serviceUser.id,
      });
    } else {
      otherUsers.push({
        ...lastFallWithoutFalseAlarm,
        room: serviceUser.userstring,
        serviceUserId: serviceUser.id,
      });
    }
  });

  // order the falls by timestamp
  liveFalls.sort((a, b) => b.timestamp - a.timestamp);
  recentFalls.sort((a, b) => b.timestamp - a.timestamp);

  // Sort other users by room name - assuming that the name will be "Bedroom NUMBER"
  otherUsers.sort((a, b) => {
    const roomA = a.room.match(/(\D+)(\d+)/); // Separate non-numeric and numeric parts
    const roomB = b.room.match(/(\D+)(\d+)/);

    if (roomA[1] === roomB[1]) {
      // Compare numeric parts as numbers
      return parseInt(roomA[2]) - parseInt(roomB[2]);
    }
    // Compare the non-numeric parts
    return roomA[1].localeCompare(roomB[1]);
  });

  return { liveFalls, recentFalls, otherUsers };
};
