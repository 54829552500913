import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
import { getOrganisation, postFakeFall } from "@intelligentlilli/api-layer";
import theme from "../../Styles/theme.scss";
import { format } from "date-fns";
import LilliButton from "../DesignSystemComponents/LilliButton/LilliButton";
import { SERVER } from "../../Services/config";

const FallsDashboardHeader = ({ error, lastFetched, getError }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const server = useSelector((state) => state.session.server);
  const [organisation, setOrganisation] = useState();
  const [loadingFakeFall, setLoadingFakeFall] = useState(false);
  const [fakeFallError, setFakeFallError] = useState(false);

  useEffect(() => {
    if (user.organisationId) {
      getOrganisation(server, user.organisationId, "web")
        .then((res) => res.ok && setOrganisation(res.body))
        .catch((err) => {
          console.log(err);
          if (err.status === 401) {
            navigate("/login");
          }
        });
    }
  }, [server, user.organisationId, navigate]);

  const triggerDummyFall = async () => {
    setLoadingFakeFall(true);
    setFakeFallError(false);
    try {
      const fakeFallRes = await postFakeFall({
        server: SERVER,
        hubId: 559,
        origin: "web",
      });
      console.log("fakeFallRes", fakeFallRes);
      if (!fakeFallRes.ok) {
        setFakeFallError(true);
      }
    } catch (error) {
      console.log("Error triggering dummy fall", error);
      setFakeFallError(true);
    }
    setLoadingFakeFall(false);
  };

  return (
    <div className={styles.dashboard_header_container}>
      <div className={styles.dashboard_header_container_left}>
        <img
          src="/images/CareHomeLogo.png"
          alt="Location of QR code"
          width={110}
        />
        <div>
          <h1 className={styles.page_title}>Dashboard</h1>
          <div className={styles.user_info} data-private>
            {user?.forenames} {user?.surname} | {organisation?.name || ""}
          </div>
        </div>
      </div>
      <div className={styles.dashboard_header_container_right}>
        {getError ? (
          <div>
            <h3 className={styles.error}>Unable to fetch data</h3>
          </div>
        ) : null}
        {lastFetched ? (
          <div>Last fetched: {format(lastFetched, "dd/MM/yy HH:mm")}</div>
        ) : null}
        {error && (
          <div>
            <h3 className={styles.error}>{error}</h3>
            <div>
              Check your connection and, if problem persists, please contact
              support on:
            </div>
            <a
              href="mailto: support@intelligentlilli.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: theme.secondary3 }}
            >
              support@intelligentlilli.com
            </a>
          </div>
        )}
        {fakeFallError && (
          <h3 className={styles.error}>Cannot trigger dummy fall right now.</h3>
        )}

        {/* We only show this button for our dev organisation LilliFalls */}
        {user.organisationId === 12 && (
          <LilliButton onClick={triggerDummyFall} loading={loadingFakeFall}>
            Trigger dummy fall
          </LilliButton>
        )}
      </div>
    </div>
  );
};

export default FallsDashboardHeader;
