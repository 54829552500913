import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHubFalls } from "@intelligentlilli/api-layer";
import { SERVER } from "../config";
// State
import { updateServiceUserData } from "../../State/slices/serviceUsersData-slice";
// Utils
import { areArraysEqualById } from "../../Services/Utils/";

export const useFetchFalls = (isFalls) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState(null); // boolean
  const [loading, setLoading] = useState(false);
  const [lastFetched, setLastFetched] = useState(null); // string | null

  const initialFetchDone = useRef(false); // Track initial fetch completion

  const serviceUsers = useSelector(
    (state) => state.serviceUsers,
    (prev, next) => areArraysEqualById(prev, next) // custom equality check
  );

  // Memoize serviceUserIds
  const serviceUserIds = serviceUsers.map((su) => su.id); // TO DO FALLS: remove when pushing to PROD
  const stringifiedIds = JSON.stringify(serviceUserIds);

  useEffect(() => {
    const arrayOfIDs = JSON.parse(stringifiedIds);

    if (!isFalls && (!arrayOfIDs || arrayOfIDs.length === 0)) return;

    const fetchFalls = async (suIDs) => {
      if (!initialFetchDone.current) setLoading(true);

      suIDs?.map(async (hubId) => {
        try {
          const hubFallsData = await getHubFalls({
            server: SERVER,
            hubId,
            origin: "web",
          });
          if (hubFallsData.ok && hubFallsData?.body) {
            dispatch(
              updateServiceUserData({
                hubId: hubId,
                update: {
                  falls: hubFallsData.body,
                },
              })
            );
            setLastFetched(new Date());
            setError(false);
          } else {
            console.error("Res not okay for getHubFalls, hubId:", hubId);
            setError(true);
          }
        } catch (error) {
          if (error.status === 401) {
            return navigate("/login");
          } else {
            console.error("Error with getHubFalls:", error);
            // Don't show the error message if the issue is the lack of device "No devices found for service user"
            if (error.status !== 404) {
              setError(true);
            }
          }
        } finally {
          // Mark initial fetch as done after completion
          if (!initialFetchDone.current) {
            setLoading(false);
            initialFetchDone.current = true;
          }
        }
      });
    };

    fetchFalls(arrayOfIDs);

    const interval = window.setInterval(() => {
      fetchFalls(arrayOfIDs);
      console.log("fetched falls");
    }, 15001); // every 15 seconds

    // Remove polling when the component unmounts
    return () => interval && window.clearInterval(interval);
  }, [stringifiedIds, isFalls, navigate, dispatch]);

  return { error, lastFetched, loading };
};
