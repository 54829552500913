import React from "react";
import styles from "./styles.module.scss"; // generic form styles
import { Form, Field } from "react-final-form";
import Checkbox from "../../Components/Checkbox";
import LilliButton from "../../Components/DesignSystemComponents/LilliButton/LilliButton";

const mapValuesToMetadata = (values) => {
  const filterTruthyValues = (fields) => {
    return Object.entries(fields).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value; // Include only truthy values
      }
      return acc;
    }, {});
  };

  return {
    observation: filterTruthyValues({
      sawFallOccur: values.sawFallOccur ?? false,
      didNotSeeFallOccur: values.didNotSeeFallOccur ?? false,
    }),
    cause: filterTruthyValues({
      environmentalHazard: values.environmentalHazard || false,
      lossOfBalance: values.lossOfBalance || false,
      medicalEvent: values.medicalEvent || false,
      slipped: values.slippedTripped || false,
      other: values.otherCause ? values.otherCauseText || "" : "", // handle optional text field
    }),
    outcome: filterTruthyValues({
      familyContacted: values.familyContacted || false,
      observationRequired: values.observationRequired || false,
      ambulanceCalled: values.ambulanceRequired || false,
      doctorContacted: values.doctorContacted || false,
      minorInjury: values.bruising || false,
      stable: values.noInjury || false,
      painDiscomfort: values.pain || false,
      other: values.otherOutcome ? values.otherOutcomeText || "" : "", // handle optional text field
    }),
    falseAlarm: values.falseAlarm,
    summary: values.summary || "",
    version: 1,
  };
};

const ResolveFallForm = ({ onSubmit, closeModal }) => {
  const validate = (values) => {
    const errors = {};
    const allFields = [
      // observation
      "sawFallOccur",
      "didNotSeeFallOccur",
      // cause
      "slippedTripped",
      "lossOfBalance",
      "medicalEvent",
      "environmentalHazard",
      "otherCause",
      // outcome
      "noInjury",
      "bruising",
      "pain",
      "observationRequired",
      "ambulanceRequired",
      "doctorContacted",
      "familyContacted",
      "otherOutcome",
      // falsealarm
      "falseAlarm",
      // summary
      "summary",
    ];

    // Check if at least one checkbox is ticked
    const anySelected = allFields.some((field) => values[field] === true);
    if (!anySelected) {
      errors._global = "Please select at least one checkbox.";
    }

    return errors;
  };

  const handleFallResolveForm = async (values) => {
    const transformedData = mapValuesToMetadata(values);

    await onSubmit(transformedData);
  };
  return (
    <Form
      onSubmit={(values) => handleFallResolveForm(values)}
      validate={validate}
      render={({ handleSubmit, submitting, hasValidationErrors, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          {/* SAW FALL SECTION */}
          <div className={styles.section}>
            <h2 className={styles.label}>Did you witness this fall?</h2>
            <Field name="sawFallOccur" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Yes, I saw this fall happen</span>
                </label>
              )}
            </Field>
            <Field name="didNotSeeFallOccur" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>No, I did not see the fall</span>
                </label>
              )}
            </Field>
          </div>
          {/* CAUSE SECTION */}
          <div className={styles.section}>
            <h2 className={styles.label}>
              Cause of the fall (Select all that apply)
            </h2>
            <Field name="slippedTripped" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Slipped/tripped</span>
                </label>
              )}
            </Field>
            <Field name="lossOfBalance" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Loss of balance</span>
                </label>
              )}
            </Field>
            <Field name="medicalEvent" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Medical event (e.g. fainting, seizure)</span>
                </label>
              )}
            </Field>
            <Field name="environmentalHazard" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>
                    Environmental hazard (e.g. wet floor, poor lighting)
                  </span>
                </label>
              )}
            </Field>
            <Field name="otherCause" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Other</span>
                </label>
              )}
            </Field>
            {/* Conditionally Rendered Text Input */}
            <Field name="otherCause" subscription={{ value: true }}>
              {({ input: { value } }) =>
                value ? (
                  <Field name="otherCauseText">
                    {({ input }) => (
                      <div>
                        <input
                          {...input}
                          type="text"
                          className={styles.input}
                          placeholder="Please specify..."
                        />
                      </div>
                    )}
                  </Field>
                ) : null
              }
            </Field>
          </div>
          {/* OUTCOME SECTION */}
          <div className={styles.section}>
            <h2 className={styles.label}>
              Outcome of the fall (Select all that apply)
            </h2>
            <Field name="noInjury" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>No injury / Stable condition</span>
                </label>
              )}
            </Field>
            <Field name="bruising" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Bruising / Minor injury</span>
                </label>
              )}
            </Field>
            <Field name="pain" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Pain or discomfort (no serious injury)</span>
                </label>
              )}
            </Field>
            <Field name="observationRequired" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Observation required (monitoring for changes)</span>
                </label>
              )}
            </Field>
            <Field name="ambulanceRequired" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Ambulance Called (potential serious injury)</span>
                </label>
              )}
            </Field>
            <Field name="doctorContacted" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Doctor to be contacted</span>
                </label>
              )}
            </Field>
            <Field name="familyContacted" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Family to be contacted</span>
                </label>
              )}
            </Field>
            <Field name="otherOutcome" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Other</span>
                </label>
              )}
            </Field>
            {/* Conditionally Rendered Text Input */}
            <Field name="otherOutcome" subscription={{ value: true }}>
              {({ input: { value } }) =>
                value ? (
                  <Field name="otherOutcomeText">
                    {({ input }) => (
                      <div>
                        <input
                          {...input}
                          type="text"
                          className={styles.input}
                          placeholder="Please specify..."
                        />
                      </div>
                    )}
                  </Field>
                ) : null
              }
            </Field>
          </div>
          {/* FALSE ALARM SECTION */}
          <div className={styles.section}>
            <h2 className={styles.label}>False alarm</h2>
            <Field name="falseAlarm" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>This was a false alarm</span>
                </label>
              )}
            </Field>
          </div>
          {/* SUMMARY SECTION */}
          <div>
            <Field name="summary" type="freeText" initialValue={""}>
              {({ input }) => {
                return (
                  <>
                    <h2 className={styles.text_area_label}>
                      Summary of the incident (Briefly describe what happened)
                    </h2>
                    <textarea
                      data-private
                      name="monitoring"
                      placeholder="Please specify..."
                      maxLength={601}
                      className={styles.text_area}
                      {...input}
                    />
                  </>
                );
              }}
            </Field>
          </div>
          {hasValidationErrors ? (
            <div className={styles.error_message}>{errors._global}</div>
          ) : null}
          <div className={styles.buttons}>
            <LilliButton variant="secondary" onClick={closeModal}>
              Cancel
            </LilliButton>
            <LilliButton
              onClick={handleSubmit}
              disabled={submitting || hasValidationErrors}
              type="submit"
            >
              Submit action
            </LilliButton>
          </div>
        </form>
      )}
    ></Form>
  );
};

export default ResolveFallForm;
